<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
                <b-row>
                <b-col lg="12" sm="12">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="fiscal_year_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="manpower.fiscal_year_id"
                                            :options="fiscalYearList"
                                            id="fiscal_year_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                                    <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="org_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="manpower.org_id"
                                            :options="orgList"
                                            id="org_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="office_type_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="manpower.office_type_id"
                                            :options="officeTypeList"
                                            id="office_type_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="office_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('org_pro.office_namel')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="manpower.office_id"
                                            :options="officeList"
                                            id="office_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Employee Name (En)" vid="name" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="name"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_accommodation.employee_name_en')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="name"
                                            v-model="manpower.name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Employee Name (Bn)" vid="name_bn" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="name_bn"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_accommodation.employee_name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="name_bn"
                                            v-model="manpower.name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Mobile" vid="mobile_no" rules="required|min:11|max:11">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="mobile_no"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_accommodation.mobile')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            type="number"
                                            id="mobile_no"
                                            v-model="manpower.mobile_no"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Date of Birth" vid="date_of_birth" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="date_of_birth"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{$t('globalTrans.dob')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            class="fromDate"
                                            v-model="manpower.date_of_birth"
                                            :placeholder="$t('globalTrans.select_date')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="File" vid="file">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="file"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{ $t('globalTrans.upload_files') }}
                                            </template>
                                            <b-form-file
                                                id="file"
                                                @change="onChangeFile"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-file>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="NID" vid="nid_no">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="nid_no"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_accommodation.nid')}}
                                            </template>
                                            <b-form-input
                                            type="number"
                                            id="nid_no"
                                            v-model="manpower.nid_no"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Father Name (En)" vid="father_name">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="father_name"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_accommodation.father_name_en')}}
                                            </template>
                                            <b-form-input
                                            id="father_name"
                                            v-model="manpower.father_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Father Name (Bn)" vid="father_name_bn">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="father_name_bn"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_accommodation.father_name_bn')}}
                                            </template>
                                            <b-form-input
                                            id="father_name_bn"
                                            v-model="manpower.father_name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Address (En)" vid="address_en">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="address_en"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_accommodation.address_en')}}
                                            </template>
                                            <b-form-input
                                            id="address_en"
                                            v-model="manpower.address"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Address (Bn)" vid="address_bn">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="address_bn"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_accommodation.address_bn')}}
                                            </template>
                                            <b-form-input
                                            id="address_bn"
                                            v-model="manpower.address_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Working Field" vid="working_field">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="working_field"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_accommodation.working_field')}}
                                            </template>
                                            <b-form-input
                                            id="working_field"
                                            v-model="manpower.working_field"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Salary Amount" vid="salary_amount">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="salary_amount"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_accommodation.salary_amount')}}
                                            </template>
                                            <b-form-input
                                                type="number"
                                                id="salary_amount"
                                                v-model="manpower.salary_amount"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Remarks (En)"  vid="remark" rules="">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="remark"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_config.remarks_en')}}
                                            </template>
                                            <b-form-textarea
                                                rows="2"
                                                id="remark"
                                                v-model="manpower.remark"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Remarks (Bn)"  vid="remark_bn" rules="">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="remark_bn"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_config.remarks_bn')}}
                                            </template>
                                            <b-form-textarea
                                                rows="2"
                                                id="remark_bn"
                                                v-model="manpower.remark_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                    </ValidationObserver>
                </b-col>
                </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { manpowerManagementStore, manpowerManagementUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.manpower = Object.assign({}, this.manpower, {
      org_id: this.$store.state.dataFilters.orgId,
      fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
    })
    if (this.id) {
      const tmp = this.getManpowerData()
      this.manpower = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      manpower: {
        id: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        name: '',
        name_bn: '',
        mobile_no: '',
        father_name: '',
        father_name_bn: '',
        nid_no: '',
        address: '',
        address_bn: '',
        remark: '',
        remark_bn: '',
        file: '',
        status: 1
      },
      officeTypeList: [],
      loading: false,
      officeList: []
    }
  },
  computed: {
    orgList: function () {
        if (this.$store.state.Auth.authUser.org_id === 12) {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
        } else {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        }
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'manpower.org_id': function (newValue) {
        this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'manpower.office_type_id': function (newValue) {
        this.officeList = this.getOfficeList(newValue)
    }
  },
  methods: {
    onChangeFile (e) {
      this.manpower.file = e.target.files[0]
    },
    getManpowerData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getOfficeTypeList (orgId) {
       const office = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
       if (orgId) {
         return office.filter(item => item.org_id === orgId)
       }
       return office
    },
    getOfficeList (officeTypeId = null) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
            return officeList.filter(office => office.office_type_id === officeTypeId)
        }
        return officeList
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      var manpower = new FormData()
      Object.keys(this.manpower).map(key => {
        manpower.append(key, this.manpower[key])
      })
      if (this.manpower.id) {
        manpower.append('_method', 'PUT')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${manpowerManagementUpdate}/${this.id}`, manpower)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, manpowerManagementStore, manpower)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
